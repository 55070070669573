import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { FileUploader } from "react-drag-drop-files";
import React, { useState, useEffect } from "react";
import XMLParser from "react-xml-parser";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
import Cookies from "universal-cookie";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";

import ReturnModal from "./sections/ReturnModal";

const cookies = new Cookies();

const adminUsers = [
  "True Hearing Health",
  "Taylor Field",
  "Brian Field",
  "TrueHearing%20Health",
  "TrueHearing Health",
];

(function () {
  if (typeof Object.defineProperty === "function") {
    try {
      Object.defineProperty(Array.prototype, "sortBy", { value: sb });
    } catch (e) {}
  }
  if (!Array.prototype.sortBy) Array.prototype.sortBy = sb;

  function sb(f) {
    for (var i = this.length; i; ) {
      var o = this[--i];
      this[i] = [].concat(f.call(o, o, i), o);
    }
    this.sort(function (a, b) {
      for (var i = 0, len = a.length; i < len; ++i) {
        if (a[i] !== b[i]) return a[i] < b[i] ? -1 : 1;
      }
      return 0;
    });
    for (i = this.length; i; ) {
      this[--i] = this[i][this[i].length - 1];
    }
    return this;
  }
})();

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className="tabpanelmark"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
    key: index,
  };
}
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

let formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const ADPTracker = () => {
  const fileTypes = ["XML"];
  const [value, setValue] = React.useState(
    parseInt(cookies.get("activeClinic")) || 0
  );
  let fileReader;
  const [hasError, setHasError] = useState([]);
  const [firstRun, setFirstRun] = useState(true);
  const [fileName, setFileName] = useState();
  const [renameWarning, setRenameWarning] = useState();
  const [file, setFile] = useState();
  const [XMLFile, setXMLFile] = useState();
  const [parsingFile, setParsingFile] = useState(false);
  const [XMLDetails, setXMLDetails] = useState();
  const [submitResult, setSubmitResult] = useState("");
  const [username, setUsername] = useState("contact@truehearinghealth.com");
  const [password, setPassword] = useState("XLwzxiK25v6*44K");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    cookies.set("activeClinic", newValue, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
  };
  const handleFileRead = (e) => {
    const content = fileReader.result;

    setHasError([]);
    // console.log('wee', parseString(content));
    var xml = new XMLParser().parseFromString(content); // Assume xmlText contains the example XML
    setXMLFile(content);
    const section1 = xml.children[0].children[2].children;
    const section2 = xml.children[0].children[3].children;
    const section3 = xml.children[0].children[4].children;
    const section4 = xml.children[0].children[5].children;
    const adpForm = section1.concat(section2).concat(section3).concat(section4);

    console.log("adpForm", adpForm);

    const allowEmptyFields = [
      "applicantMiddleinitial",
      "streetNo",
      "nameLTCH",
      "unitNo",
      "rrRoute",
      "busPhone",
      "phoneExtension",
      "confirmationOfBenefit",
      "hearingAids",
      "other",
      "reason",
      "confirmation",
      "sig",
      "contact",
      "physician",
      "homePhone",
      "audiologist",
      "authorizer",
      "vendor",
      "equipmentSpec",
      "proofOfDelivery",
    ];
    let emptyhomePhone = false;

    let sigCol = [];
    let agentApplying = false;
    setFirstRun(false);
    let ADPportion = 0;
    let docSigDate = "";
    let patFinalSigDate = "";
    adpForm.map((field) => {
      //empty phone numbers
      if (field.value === "" && field.name === "homePhone") {
        emptyhomePhone = true;
      }

      if (field.name === "applicantFirstname" && field.value !== "") {
        setXMLDetails((prevState) => ({
          ...prevState,
          applicantFirstname: field.value,
        }));
      }
      if (field.name === "applicantLastname" && field.value !== "") {
        setXMLDetails((prevState) => ({
          ...prevState,
          applicantLastname: field.value,
        }));
      }
      if (field.name === "vendor" && field.children[10].value !== "") {
        setXMLDetails((prevState) => ({
          ...prevState,
          invoiceNo: field.children[10].value,
        }));
      }

      if (field.name === "homePhone" && field.value !== "") {
        setXMLDetails((prevState) => ({
          ...prevState,
          phone: field.value,
        }));
      }
      if (field.name === "DateOfBirth" && field.value !== "") {
        setXMLDetails((prevState) => ({
          ...prevState,
          date_of_birth: field.value,
        }));
      }
      if (field.name === "healthNo" && field.value !== "") {
        setXMLDetails((prevState) => ({
          ...prevState,
          healthcard_four: field.value.substring(6, 10),
        }));
      }
      if (field.name === "homePhone" && field.value === "") {
        setHasError((hasError) => [...hasError, `Home phone is empty `]);
      }
      if (
        field.name === "healthNo" &&
        field.value.match(/[0-9]{10}/g) === null
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Healthcard is not correct format (10 digits)`,
        ]);
      }
      if (
        field.name === "versionNo" &&
        field.value.match(/[A-Z]{2}/g) === null
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Healthcard version code is not correct format (2 letters)`,
        ]);
      }
      if (
        field.name === "busPhone" &&
        field.value.replace(/[^\d]/g, "").length !== 10 &&
        field.value !== ""
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Business phone might not be 10 digits`,
        ]);
      }
      if (
        field.value === "" &&
        field.name === "busPhone" &&
        emptyhomePhone === true
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Home phone and business phone cannot both be empty.`,
        ]);
      }
      //confirmation of benefits
      if (field.name === "confirmationOfBenefit") {
        if (
          field.children[0].value === "yes" &&
          field.children[1].value === ""
        ) {
          setHasError((hasError) => [
            ...hasError,
            `If patient is receiving social benefits must check which program on page 1.`,
          ]);
        }
      }
      if (field.name === "confirmationOfBenefit") {
        if (field.children[0].value === "") {
          setHasError((hasError) => [
            ...hasError,
            `Missing check for confirmation of benefits on page 1.`,
          ]);
        }
        if (
          field.children[2].value === "" ||
          field.children[2].value === "yes"
        ) {
          setHasError((hasError) => [
            ...hasError,
            `Problem with WSIB Checkbox on page 1.`,
          ]);
        }
        if (
          field.children[3].value === "" ||
          field.children[2].value === "yes"
        ) {
          setHasError((hasError) => [
            ...hasError,
            `Problem with VAC Checkbox on page 1.`,
          ]);
        }
      }

      //broad empty field check
      if (field.value === "" && !allowEmptyFields.includes(field.name)) {
        setHasError((hasError) => [
          ...hasError,
          `${field.name} cannot be empty.`,
        ]);
      }
      //physician checks
      // if (field.name === "physician") {
      //   if (
      //     field.children[0].value === "" ||
      //     field.children[1].value === "" ||
      //     field.children[2].value === ""
      //   ) {
      //     setHasError((hasError) => [
      //       ...hasError,
      //       `Physician name or phone is missing on page 4.`,
      //     ]);
      //   }
      //   if (field.children[4].value === "") {
      //     setHasError((hasError) => [
      //       ...hasError,
      //       `Physician billing # is missing on page 4, please add to Blueprint physician list as well.`,
      //     ]);
      //   }
      // }
      //reason for application
      if (field.name === "reason") {
        if (
          field.children[0].children[0].value === "" &&
          field.children[0].children[2].value === ""
        ) {
          setHasError((hasError) => [
            ...hasError,
            `Reason for application is missing on page 2.`,
          ]);
        }
        if (
          field.children[0].children[0].value !== "" &&
          field.children[0].children[2].value !== ""
        ) {
          setHasError((hasError) => [
            ...hasError,
            `Both 'first access' and 'replacement' should not be checked on page 2.`,
          ]);
        }
        // if field.children[1].children[1].value
        // field.children[1].children[2].value

        if (
          (field.children[0].children[0].value === "firstAccess" &&
            field.children[1].children[0].value !== "") ||
          (field.children[0].children[0].value === "firstAccess" &&
            field.children[1].children[1].value !== "") ||
          (field.children[0].children[0].value === "firstAccess" &&
            field.children[1].children[2].value !== "")
        ) {
          setHasError((hasError) => [
            ...hasError,
            `If first access, replacement boxes shouldn't be checked on page 2.`,
          ]);
        }
        if (field.children[1].children[2].value) {
        }
        if (field.children[0].children[2].value === "replacement") {
          console.log("got replacement");
          if (
            field.children[1].children[1].value === "normalWear" &&
            field.children[1].children[2].value !== "vendorQuote"
          ) {
            setHasError((hasError) => [
              ...hasError,
              `If reason for application is replacement, 'normal wear' and 'vendor quote' boxes must both be checked on page 2.`,
            ]);
          }
          if (
            field.children[1].children[1].value !== "normalWear" &&
            field.children[1].children[2].value === "vendorQuote"
          ) {
            setHasError((hasError) => [
              ...hasError,
              `If reason for application is replacement, 'normal wear' and 'vendor quote' boxes must both be checked on page 2.`,
            ]);
          }
          if (
            field.children[1].children[1].value !== "" &&
            field.children[1].children[0].value !== ""
          ) {
            setHasError((hasError) => [
              ...hasError,
              `Only 1 replacement reason should be checked on page 2.`,
            ]);
          }
        }
      }
      //agent is applying
      if (field.name === "sig" && field.children[1].value === "agent") {
        agentApplying = true;
      }
      if (agentApplying === true && field.name === "contact") {
        if (
          field.children[0].value === "" ||
          field.children[1].value === "" ||
          field.children[2].value === "" ||
          field.children[5].value === "" ||
          field.children[6].value === "" ||
          field.children[8].value === "" ||
          field.children[9].value === "" ||
          field.children[10].value === "" ||
          (field.children[11].value === "" && field.children[12].value === "")
        ) {
          setHasError((hasError) => [
            ...hasError,
            `Agent details or relationship is missing on page 3.`,
          ]);
        }
      }

      //check type of aid checked
      let noaids = true;
      if (field.name === "hearingAids") {
        field.children.forEach((aid) => {
          if (aid.value !== "") {
            noaids = false;
          }
        });
        if (noaids === true) {
          setHasError((hasError) => [
            ...hasError,
            `No hearing device types are checked on the bottom of page 1`,
          ]);
        }
      }

      //patient sig date one
      if (field.name === "sig" && field.children[2].value !== "") {
        sigCol.push({
          name: "1st Patient signature",
          page: 3,
          date: field.children[2].value,
        });
      }
      //empty patient signature one
      if (field.name === "sig" && field.children[1].value === "") {
        setHasError((hasError) => [
          ...hasError,
          `Applicant vs. Agent checkbox missing on page 3.`,
        ]);
      }

      //patient sig date missing one
      if (field.name === "sig" && field.children[2].value === "") {
        setHasError((hasError) => [
          ...hasError,
          `Patient signature date on page 3 is missing.`,
        ]);
      }
      //invalid patient sig
      if (
        field.name === "sig" &&
        isNaN(Date.parse(field.children[2].value)) === true
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Patient signature date on page 3 is invalid.`,
        ]);
      }
      if (
        field.name === "sig" &&
        isNaN(Date.parse(field.children[2].value)) === false
      ) {
        let patientSigDateTemp = Date.parse(field.children[2].value);
        let todays_date = new Date().getTime();
        let diff = todays_date - patientSigDateTemp;
        let diffDays = Math.floor(diff / 1000 / 60 / 60 / 24);
        if (todays_date - patientSigDateTemp < 0 || diffDays > 365) {
          setHasError((hasError) => [
            ...hasError,
            `Patient signature date on page 3 is more than 365 days in the past or is in the future or invalid.`,
          ]);
        }
      }
      //patient sig date Two
      if (field.name === "proofOfDelivery" && field.children[1].value !== "") {
        sigCol.push({
          name: "2nd Patient signature",
          page: 5,
          date: field.children[1].value,
        });
      }
      //empty patient signature Two
      if (field.name === "proofOfDelivery" && field.children[1].value === "") {
        setHasError((hasError) => [
          ...hasError,
          `Patient signature (Proof of delivery) Date missing on page 5.`,
        ]);
      }
      //invalid patient sig two
      if (
        field.name === "proofOfDelivery" &&
        isNaN(Date.parse(field.children[1].value)) === true
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Patient signature (proof of delivery) date on page 5 is invalid.`,
        ]);
      }
      if (
        field.name === "proofOfDelivery" &&
        isNaN(Date.parse(field.children[1].value)) === false
      ) {
        let patientSigDateTemp = Date.parse(field.children[1].value);
        let todays_date = new Date().getTime();
        let diff = todays_date - patientSigDateTemp;
        let diffDays = Math.floor(diff / 1000 / 60 / 60 / 24);
        if (todays_date - patientSigDateTemp < 0 || diffDays > 365) {
          setHasError((hasError) => [
            ...hasError,
            `Patient signature date on page 5 is more than 365 days in the past or is in the future or invalid.`,
          ]);
        }
      }
      //empty vendor reg number

      if (field.name === "vendor" && field.children[1].value === "") {
        setHasError((hasError) => [
          ...hasError,
          `Vendor Registration Number is missing.`,
        ]);
      }
      //vendor sig date
      if (field.name === "vendor" && field.children[9].value !== "") {
        sigCol.push({
          name: "Vendor signature",
          page: 4,
          date: field.children[9].value,
        });
      }
      //empty vendor signature
      if (field.name === "vendor" && field.children[9].value === "") {
        setHasError((hasError) => [
          ...hasError,
          `Vendor signature Date missing on page 4.`,
        ]);
      }

      //invalid vendor sig
      if (
        field.name === "vendor" &&
        isNaN(Date.parse(field.children[9].value)) === true
      ) {
        setHasError((hasError) => [
          ...hasError,
          `vendor signture date on page 4 is invalid.`,
        ]);
      }
      if (
        field.name === "vendor" &&
        isNaN(Date.parse(field.children[9].value)) === false
      ) {
        let patientSigDateTemp = Date.parse(field.children[9].value);
        let todays_date = new Date().getTime();
        let diff = todays_date - patientSigDateTemp;
        let diffDays = Math.floor(diff / 1000 / 60 / 60 / 24);
        if (todays_date - patientSigDateTemp < 0 || diffDays > 365) {
          setHasError((hasError) => [
            ...hasError,
            `vendor signature date on page 4 is more than 365 days in the past or is in the future or invalid.`,
          ]);
        }
      }
      //doctor sig date
      // if (field.name === "physician" && field.children[6].value !== "") {
      //   sigCol.push({
      //     name: "Doctor signature",
      //     page: 4,
      //     date: field.children[6].value,
      //   });
      // }
      // //empty doctor signature
      // if (field.name === "physician" && field.children[6].value === "") {
      //   setHasError((hasError) => [
      //     ...hasError,
      //     `Doctor signature Date missing on page 4.`,
      //   ]);
      // }

      // //invalid doctor sig
      // if (
      //   field.name === "physician" &&
      //   isNaN(Date.parse(field.children[6].value)) === true
      // ) {
      //   setHasError((hasError) => [
      //     ...hasError,
      //     `doctor sig date on page 4 is invalid.`,
      //   ]);
      // }
      // if (
      //   field.name === "physician" &&
      //   isNaN(Date.parse(field.children[6].value)) === false
      // ) {
      //   let patientSigDateTemp = Date.parse(field.children[6].value);
      //   let todays_date = new Date().getTime();
      //   let diff = todays_date - patientSigDateTemp;
      //   let diffDays = Math.floor(diff / 1000 / 60 / 60 / 24);
      //   if (todays_date - patientSigDateTemp < 0 || diffDays > 365) {
      //     setHasError((hasError) => [
      //       ...hasError,
      //       `doctor sig date on page 4 is more than 365 days in the past or is in the future or invalid.`,
      //     ]);
      //   }
      // }
      const dateRegEx = /^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/;
      //clinician adpNum
      if (field.name === "authorizer" && field.children[4].value === "") {
        setHasError((hasError) => [
          ...hasError,
          `Clinician ADP number is missing. Check it is the correct clinician. (Clinician: ${field.children[1].value} ${field.children[0].value})`,
        ]);
      }
      //provider sig date
      if (
        (field.name === "authorizer" && field.children[6].value !== "") ||
        (field.name === "authorizer" &&
          dateRegEx.test(field.children[6].value) === false)
      ) {
        sigCol.push({
          name: "Clinician signature",
          page: 4,
          date: field.children[6].value,
        });
      }
      //empty provider signature
      if (
        (field.name === "authorizer" && field.children[6].value === "") ||
        (field.name === "authorizer" &&
          dateRegEx.test(field.children[6].value) === false)
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Clinician signature Date missing on page 4.`,
        ]);
      }
      //invalid provider sig
      if (
        (field.name === "authorizer" &&
          isNaN(Date.parse(field.children[6].value)) === true) ||
        (field.name === "authorizer" &&
          dateRegEx.test(field.children[6].value) === false)
      ) {
        setHasError((hasError) => [
          ...hasError,
          `clinician sig date on page 4 is invalid.`,
        ]);
      }
      if (
        (field.name === "authorizer" &&
          isNaN(Date.parse(field.children[6].value)) === false) ||
        (field.name === "authorizer" &&
          dateRegEx.test(field.children[6].value) === false)
      ) {
        let patientSigDateTemp = Date.parse(field.children[6].value);
        let todays_date = new Date().getTime();
        let diff = todays_date - patientSigDateTemp;
        let diffDays = Math.floor(diff / 1000 / 60 / 60 / 24);
        if (todays_date - patientSigDateTemp < 0 || diffDays > 365) {
          setHasError((hasError) => [
            ...hasError,
            `clinician sig date on page 4 is more than 365 days in the past or is in the future or invalid.`,
          ]);
        }
      }
      //empty invoice number
      if (field.name === "vendor" && field.children[10].value === "") {
        setHasError((hasError) => [
          ...hasError,
          `Invoice number is empty on page 4`,
        ]);
      }
      //empty sides
      if (
        field.name === "equipmentSpec" &&
        field.children[0].children[0].children[0].value === "" &&
        field.children[0].children[0].children[1].value === "" &&
        field.children[0].children[0].children[2].value === ""
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Device 1 on Page 5 must have a side`,
        ]);
      }
      if (
        field.name === "equipmentSpec" &&
        field.children[0].children[1].children[0].value === "" &&
        field.children[0].children[1].children[1].value === "" &&
        field.children[0].children[1].children[2].value === ""
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Device 2 on Page 5 must have a side`,
        ]);
      }
      //empty serial numbers
      if (
        field.name === "equipmentSpec" &&
        field.children[0].children[0].children[4].value !== "" &&
        field.children[0].children[0].children[5].value === ""
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Device 1 serial number is missing on Page 5`,
        ]);
      }
      if (
        field.name === "equipmentSpec" &&
        field.children[0].children[1].children[4].value !== "" &&
        field.children[0].children[1].children[5].value === ""
      ) {
        setHasError((hasError) => [
          ...hasError,
          `Device 2 serial number is missing on Page 5`,
        ]);
      }

      if (
        field.name === "equipmentSpec" &&
        field.children[0].children[0].children[6].value !== ""
      ) {
        ADPportion =
          ADPportion +
          parseFloat(field.children[0].children[0].children[6].value);
      }
      if (
        field.name === "equipmentSpec" &&
        field.children[0].children[1].children[6].value !== ""
      ) {
        ADPportion =
          ADPportion +
          parseFloat(field.children[0].children[1].children[6].value);
      }
      // if (field.name === "audiologist" && field.children[6].value !== "") {
      //   setHasError((hasError) => [
      //     ...hasError,
      //     `Audiologist signature date should be BLANK on Page 4 (the physician is the only prescriber)`,
      //   ]);
      // }
      if (field.name === "vendor" && field.children[7].value !== "") {
        if (field.children[7].value.includes("London")) {
          console.log("ADP address is London, logging in with contact@");
          setUsername("contact@truehearinghealth.com");
          setPassword("vACEb%p6Uw!hy*m");
          field.children[1].value = "2021516";
        }

        if (field.children[7].value.includes("Sarnia")) {
          console.log("ADP address is Sarnia, logging in with contact+sarnia@");
          setUsername("contact+sarnia@truehearinghealth.com");
          setPassword("sk8D!@gqSc%T9tU");
          field.children[1].value = "2024740";
        }

        if (field.children[7].value.includes("Chatham")) {
          console.log(
            "ADP address is Chatham, logging in with contact+chatham@"
          );
          setUsername("contact+chatham@truehearinghealth.com");
          setPassword("a4z3Nv!ZK#d$gQG4");
          field.children[1].value = "2022610";
        }

        if (field.children[7].value.includes("Windsor")) {
          console.log(
            "ADP address is Windsor, logging in with contact+windsor@"
          );
          setUsername("contact+windsor@truehearinghealth.com");
          setPassword("l*zNLLn%z4g$A2k6");
          field.children[1].value = "2023678";
        }

        if (field.children[7].value.includes("Brantford")) {
          console.log(
            "ADP address is Brantford, logging in with contact+brantford@"
          );
          setUsername("contact+brantford@truehearinghealth.com");
          setPassword("a4UbZyvjh%YQM6*3");
          field.children[1].value = "2023677";
        }
      }
      setXMLDetails((prevState) => ({
        ...prevState,
        ADPportion: ADPportion,
      }));

      return null;
    });

    sigCol.map((sig) => {
      let sigDate = Date.parse(sig.date);
      let dateDiff = Date.now() - sigDate;
      let dateDiffDays = dateDiff / (1000 * 60 * 60 * 24);

      if (isNaN(sigDate) || sigDate < 0) {
        setHasError((hasError) => [
          ...hasError,
          `${sig.name} does not have a valid date on page ${sig.page}.`,
        ]);
      } else if (dateDiffDays >= 365) {
        setHasError((hasError) => [
          ...hasError,
          `${sig.name} date is more than 1 year in the past on page ${sig.page}.`,
        ]);
      }
      return null;
    });

    //check signature order
    //find which sigcol value holds patientsignature date based on sigCol.name = 1st Patient signature
    let patientSigDateOne = Date.parse(
      sigCol.find((sig) => sig.name === "1st Patient signature").date
    );
    let patientSigDateTwo = Date.parse(
      sigCol.find((sig) => sig.name === "2nd Patient signature").date
    );
    let providerSig = Date.parse(
      sigCol.find((sig) => sig.name === "Clinician signature").date
    );
    // let doctorSig = Date.parse(
    //   sigCol.find((sig) => sig.name === "Doctor signature").date
    // );
    let vendorSig = Date.parse(
      sigCol.find((sig) => sig.name === "Vendor signature").date
    );
    // console.log("doctorSig: " + doctorSig);
    console.log("patientSigDateTwo: " + patientSigDateTwo);

    //check signature order
    //patient 1st sig after patient 2nd sig
    if (patientSigDateOne > patientSigDateTwo) {
      setHasError((hasError) => [
        ...hasError,
        `Patient 1st signature cannot be after patient 2nd signature.`,
      ]);
    }
    //patient 1st sig after doctorSig
    // if (patientSigDateOne > doctorSig) {
    //   setHasError((hasError) => [
    //     ...hasError,
    //     `Patient 1st signature should be before doctor's signature.`,
    //   ]);
    // }
    // if (patientSigDateTwo < doctorSig) {
    //   setHasError((hasError) => [
    //     ...hasError,
    //     `Patient final signature should be after doctor's signature.`,
    //   ]);
    // }
    //patient 1st sig after vendorSig
    if (patientSigDateOne > vendorSig) {
      setHasError((hasError) => [
        ...hasError,
        `Patient 1st signature cannot be after THH's signature.`,
      ]);
    }
    //patient 1st sig after providerSig
    if (patientSigDateOne > providerSig) {
      setHasError((hasError) => [
        ...hasError,
        `Patient 1st signature cannot be after provider signature.`,
      ]);
    }
    //doctorSig after
    // if (doctorSig < providerSig) {
    //   setHasError((hasError) => [
    //     ...hasError,
    //     `Doctor signature should be on or after clinician's signature.`,
    //   ]);
    // }
    setParsingFile(false);
  };

  const handleFileChosen = (file) => {
    setParsingFile(true);
    setFileName("");
    setSubmitResult("");
    setFile(file);
    setHasError([]);
    setFirstRun(true);
    setFileName(file.name);
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };
  const [siteDataContext] = useSiteDataApi();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitResult("Submitting to ADP...");

    console.log("XMLDetails", XMLDetails);
    if (
      fileName !==
      XMLDetails.applicantFirstname +
        "_" +
        XMLDetails.applicantLastname +
        "_" +
        XMLDetails.invoiceNo +
        ".xml"
    ) {
      setRenameWarning(
        `Warning only: Filename has been renamed from '${fileName}' to ${
          XMLDetails.applicantFirstname +
          "_" +
          XMLDetails.applicantLastname +
          "_" +
          XMLDetails.invoiceNo +
          ".xml"
        } for submission. `
      );
    }

    const niceObject = {
      xmlString: XMLFile,
      fileName: file.name,
      username,
      password,
      applicantFirstname: XMLDetails.applicantFirstname,
      applicantLastname: XMLDetails.applicantLastname,
      invoiceNo: XMLDetails.invoiceNo,
      date_of_birth: XMLDetails.date_of_birth,
      phone: XMLDetails.phone,
      healthcard_four: XMLDetails.healthcard_four,
      ADPportion: XMLDetails.ADPportion,
    };
    try {
      const res = await fetch("/api/ADP-submit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
        body: JSON.stringify(niceObject),
      });

      const adpResponseData = await res.json();

      if (adpResponseData) {
        setRenameWarning(null);
        if (adpResponseData) {
          console.log("got adpResponseData", adpResponseData);
          setSubmitResult(adpResponseData);
        }
      }
    } catch (error) {
      setRenameWarning(null);
      console.log("error submitting ADP", error);
      setSubmitResult("Error submitting ADP, please submit manually.");
    }
  };

  const [ADPsubmissiondata, setADPsubmissiondata] = useState([]);
  const [ADPstatusdata, setADPstatusdata] = useState([]);
  const [ADPesubmissiondata, setADPesubmissiondata] = useState([]);
  const [ADPreturnDetails, setADPreturnDetails] = useState();

  const [ADPBPdata, setADPBPdata] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await fetchADPdata();
    }
    fetchData();
  }, [submitResult]);

  const fetchADPdata = async () => {
    try {
      const res = await fetch("/api/get-adp-data", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
      });

      const data = await res.json();

      if (data) {
        // console.log('get-adp-data', data);
        setADPsubmissiondata(data.ADPsubmissionData[0]);
        setADPesubmissiondata(data.ADPesubmissionData[0]);
        setADPstatusdata(data.ADPstatusData[0]);
      }
    } catch (error) {
      console.log("error fetching get-adp-data", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      console.log("getting further data");
      await getFurtherData();
    }
    setTimeout(() => {
      fetchData();
    }, 2000);
  }, [ADPstatusdata]);

  const getFurtherData = async () => {
    // GET DATA FROM INSECURE SERVER
    try {
      const res = await fetch("/api/get-adp-bp-data", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
      });

      const adpBPdata = await res.json();

      if (adpBPdata) {
        // console.log('get-adp-bp-data', adpBPdata);
        // console.log('ADPstatusdata', ADPstatusdata);
        let ADPdata = adpBPdata.returnData[0];
        ADPdata = ADPdata.map((row) => {
          let { first_name, last_name, healthcard_four } = row;
          let niceDate = Date.parse(row.delivery_time);
          niceDate = new Date(niceDate);
          row.delivery_time = niceDate;
          let compareDate = niceDate;
          let combinedName = "";
          let combinedNameHC = "";
          if (first_name && last_name) {
            combinedName =
              first_name.toUpperCase().replace(`'`, "").substring(0, 2) +
              last_name.toUpperCase().replace(`'`, "").substring(0, 2);
          }
          if (first_name && last_name && healthcard_four) {
            combinedNameHC =
              first_name.toUpperCase().replace(`'`, "").substring(0, 2) +
              last_name.toUpperCase().replace(`'`, "").substring(0, 2) +
              healthcard_four;
          }

          row.extraStatus = [];
          //status data
          ADPstatusdata.map((status) => {
            let { client_ref, dateADP, healthcardFour } = status;
            let niceDate = Date.parse(dateADP);
            let client_ref_healthcard = client_ref + healthcardFour;
            niceDate = new Date(niceDate);
            status.dateADP = niceDate;
            if (compareDate <= niceDate) {
              if (healthcardFour) {
                if (client_ref_healthcard === combinedNameHC) {
                  row.extraStatus.push(status);
                }
              } else {
                if (client_ref === combinedName) {
                  row.extraStatus.push(status);
                }
              }
            }
            return true;
          });

          //esubmission data
          ADPesubmissiondata.map((status) => {
            let { adpStatus, filename, reportDate, adpReason } = status;

            if (typeof filename.split("_")[1] !== "undefined") {
              // console.log('before disaster');
              let computedClientRef =
                filename
                  .split("_")[0]
                  .toUpperCase()
                  .replace(`'`, "")
                  .substring(0, 2) +
                filename
                  .split("_")[1]
                  .toUpperCase()
                  .replace(`'`, "")
                  .substring(0, 2);
              // console.log('after disaster');
              let niceDate = Date.parse(reportDate);
              niceDate = new Date(niceDate);
              status.dateADP = niceDate;
              // console.log('Patient matching: ', { computedClientRef, combinedName });
              if (compareDate <= niceDate) {
                if (computedClientRef === combinedName) {
                  status.adpStatus =
                    "Submission -> " + adpStatus + " - " + adpReason;
                  row.extraStatus.push(status);
                  return null;
                }
              }
            }
            return true;
          });

          //THH website submission data
          ADPsubmissiondata.map((status) => {
            let { first_name, datesubmitted, last_name } = status;

            // console.log('before disaster');
            let computedClientRef =
              first_name.toUpperCase().replace(`'`, "").substring(0, 2) +
              last_name.toUpperCase().replace(`'`, "").substring(0, 2);
            // console.log('after disaster');
            let niceDate = Date.parse(datesubmitted);
            niceDate = new Date(niceDate);
            status.dateADP = niceDate;
            // console.log('Patient matching: ', { computedClientRef, combinedName });
            if (computedClientRef === combinedName) {
              status.adpStatus = "Submitted from THH website";
              row.extraStatus.push(status);
              return null;
            }
            return true;
          });

          if (row.extraStatus) {
            row.extraStatus = row.extraStatus.sortBy(function (o) {
              return o.dateADP;
            });
            row.extraStatus.reverse();
          }

          return row;
        });
        console.log("chad", ADPdata);
        //start sorting blueprint data by location and paid status
        const grouped = groupBy(ADPdata, (adp_patient) => adp_patient.location);
        let adpDataSortedByLocation = Array.from(grouped);
        adpDataSortedByLocation = adpDataSortedByLocation.map((location) => {
          let mapOne = groupBy(
            location[1],
            (adp_patient) => adp_patient.adp_balance
          );
          let arrayOne = Array.from(mapOne);
          location[1] = arrayOne;
          // console.log('location', location[1][0]);
          if (location[1][0][0] === "Paid") location[1].reverse();
          return location;
        });
        // console.log('blueprint data sorted by clinic and paid status: ', adpDataSortedByLocation);

        setADPBPdata(adpDataSortedByLocation);
      }
    } catch (error) {
      console.log("error fetching get-adp-bp-data", error);
    }
  };

  return (
    <Box m={3} className="ADPpage">
      <Box className="xmluploader">
        {parsingFile ? (
          <Box>
            <Grid
              container
              spacing={0}
              direction="column"
              style={{ padding: "20px", minHeight: "100px" }}
            >
              <Grid item xs={3}>
                <CircularProgress />
                <br />
                <Typography>Validating XML file...</Typography>
              </Grid>
            </Grid>
          </Box>
        ) : null}
        {firstRun ? (
          <FileUploader
            multiple={false}
            fileOrFiles={null}
            onTypeError={(err) => alert(err)}
            handleChange={(file) => {
              handleFileChosen(file);
            }}
            name="file"
            types={fileTypes}
          />
        ) : (
          <Box>
            <FileUploader
              multiple={false}
              onTypeError={(err) => alert(err)}
              // handleChange={handleChange}
              handleChange={(file) => {
                handleFileChosen(file);
              }}
              name="file"
              types={fileTypes}
            />
            <Box mt={4} mb={4}>
              <Typography component="h5" variant="h5">
                Validation for {fileName}
              </Typography>
            </Box>
            {renameWarning ? (
              <Typography
                style={{ color: "#ffb263" }}
              >{`Warning only: Filename will be renamed from '${fileName}' to ${
                XMLDetails.applicantFirstname +
                "_" +
                XMLDetails.applicantLastname +
                "_" +
                XMLDetails.invoiceNo +
                ".xml"
              } for submission.`}</Typography>
            ) : null}
            {!hasError.length ? (
              <Typography>
                This appears to be a valid submission. If this is a
                resubmission, please make sure you changed the invoice#.
              </Typography>
            ) : null}
            <ul>
              {hasError.map((error, i) => {
                return (
                  <li key={i}>
                    <Typography>{error}</Typography>
                  </li>
                );
              })}
            </ul>
            <Box mt={6}>
              <Grid container spacing={1}>
                <Grid
                  container
                  sm={4}
                  spacing={1}
                  flexDirection="column"
                  item
                  style={{
                    border: "1px solid",
                    borderRadius: "4px",
                  }}
                >
                  <form autoComplete="on" onSubmit={handleSubmit}>
                    <Grid item container justifyContent="space-between">
                      <Grid item>
                        <Typography>
                          Click the button below to submit to ADP.
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Box mb={2} m={1} display="flex">
                        <Button
                          variant="contained"
                          type="submit"
                          className={hasError.length ? "warning" : ""}
                        >
                          Automatic ADP Submission
                        </Button>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      justifyContent="center"
                      alignItems="center"
                      style={{ padding: "10px" }}
                    >
                      {submitResult === "Submitting to ADP..." ? (
                        <div style={{ textAlign: "center" }}>
                          <CircularProgress />
                          <br />
                          Submitting to ADP...
                        </div>
                      ) : submitResult ===
                        "Error submitting ADP, please submit manually." ? (
                        <>
                          <Alert severity="error">{submitResult}</Alert>
                        </>
                      ) : submitResult === "" ? null : (
                        <>
                          <Alert severity="success">{submitResult}</Alert>
                        </>
                      )}
                    </Grid>
                  </form>
                </Grid>
                <Grid item sm={3}>
                  <Button
                    variant="contained"
                    // onClick={() => }
                    href={`https://www.ebse.health.gov.on.ca/ebs/mohltc_services/index.faces`}
                    target="_blank"
                    className={hasError.length ? "warning" : ""}
                  >
                    Manual Submission (Do not use)
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Box>

      {ADPBPdata &&
      ADPBPdata[0] &&
      ADPBPdata[0][1] &&
      ADPBPdata[0][1][0] &&
      ADPBPdata[0][1][0][1] &&
      ADPBPdata[0][1][0][1][0] &&
      ADPBPdata[0][1][0][1][0].extraStatus ? (
        <Box mt={7}>
          <AppBar position="static">
            <Tabs
              value={value}
              variant="fullWidth"
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="fullwidth tabs"
            >
              {ADPBPdata.map((key, count) => {
                return (
                  <Tab
                    label={`${key[0]} (${key[1][0][1].length})`}
                    {...a11yProps(count)}
                    key={count}
                  />
                );
              })}
            </Tabs>
          </AppBar>
          {ADPBPdata.map((row, count) => {
            return (
              <TabPanel value={value} index={count} key={count}>
                {row[1].map((PaidStatus, y) => {
                  let groupedPaidStatus = PaidStatus[1];

                  let margin = 0;
                  if (PaidStatus[0] === "Paid") {
                    margin = 7;
                    groupedPaidStatus = groupedPaidStatus.reverse();
                  } else {
                    margin = 0;
                  }
                  return (
                    <Box mt={margin} key={y}>
                      <Typography component="h3" variant="h5">
                        {PaidStatus[0]} ADP Patients - {PaidStatus[1].length}
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="referral table"
                          className="referralTable"
                        >
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell style={{ width: "15%" }}>
                                Treatment Start
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "15%" }}>
                                Patient
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "10%" }}>
                                Invoice #
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "10%" }}>
                                ADP Amount
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  width:
                                    PaidStatus[0] === "Paid" ? "10%" : "50%",
                                }}
                              >
                                {PaidStatus[0] === "Paid"
                                  ? "ADP Paid"
                                  : "Extra Status"}
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {groupedPaidStatus.map((ADPpatient, y) => {
                              const {
                                delivery_time,
                                date_of_birth,
                                invoice_number,
                                phone,
                                healthcard_four,
                                first_name,
                                last_name,
                                adp_amount,
                                adp_paid,
                                extraStatus,
                              } = ADPpatient;
                              return (
                                <StyledTableRow
                                  key={y}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    <div
                                      className={
                                        delivery_time &&
                                        (new Date().getTime() - delivery_time) /
                                          (1000 * 3600 * 24) >
                                          90
                                          ? "fail"
                                          : ""
                                      }
                                    >
                                      {delivery_time.toLocaleString("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "numeric",
                                      })}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    <Tooltip
                                      enterTouchDelay={0}
                                      leaveTouchDelay={0}
                                      title={
                                        <div>
                                          DOB: {date_of_birth}
                                          <br />
                                          Phone: {formatPhoneNumber(phone)}
                                          <br />
                                          Healthcard 4: {healthcard_four}
                                        </div>
                                      }
                                    >
                                      <div>{`${first_name} ${last_name}`}</div>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>{invoice_number}</TableCell>
                                  <TableCell>
                                    {adp_amount
                                      ? `$${numberWithCommas(adp_amount)}`
                                      : null}
                                  </TableCell>
                                  <TableCell>
                                    <div
                                      className={
                                        extraStatus && extraStatus.length
                                          ? "extra"
                                          : "noextra"
                                      }
                                    >
                                      <div
                                        className={
                                          extraStatus &&
                                          extraStatus.length &&
                                          extraStatus[0].adpStatus.includes(
                                            "Fail"
                                          )
                                            ? "fail"
                                            : ""
                                        }
                                      >
                                        <div
                                          className={
                                            // prettier-ignore
                                            extraStatus && extraStatus.length && extraStatus[0].adpStatus.includes('Payment Pending') && !adp_paid
                                              ? 'paymentpending'
                                              : (extraStatus && extraStatus.length && (extraStatus[0].adpStatus.includes('Submission -> Accepted - Accepted') || (extraStatus[0].adpStatus.includes('In Process'))|| (extraStatus[0].adpStatus.includes('Approved'))) && (new Date().getTime() - extraStatus[0].dateADP) / (1000 * 3600 * 24) <= 21)
                                              ? `accepted`
                                              : extraStatus && extraStatus.length && (extraStatus[0].adpStatus.includes('Submission -> Accepted - Accepted') || (extraStatus[0].adpStatus.includes('In Process'))|| (extraStatus[0].adpStatus.includes('Approved'))) && (new Date().getTime() - extraStatus[0].dateADP) / (1000 * 3600 * 24) > 21
                                              ? 'fail' 
                                              : extraStatus && extraStatus.length && (extraStatus[0].adpStatus.includes('Submitted from THH website')&& (new Date().getTime() - extraStatus[0].dateADP) / (1000 * 3600 * 24) < 5) 
                                              ? 'accepted'
                                              : 'fail'
                                          }
                                        >
                                          {adp_paid &&
                                          extraStatus[0] &&
                                          extraStatus[0].adpStatus.includes(
                                            "Return"
                                          ) ? (
                                            <div>
                                              {extraStatus[0].adpStatus +
                                                " on " +
                                                extraStatus[0].dateADP.toDateString() +
                                                " for $" +
                                                numberWithCommas(
                                                  extraStatus[0].payment_amount
                                                )}
                                            </div>
                                          ) : adp_paid ? (
                                            <Grid
                                              container
                                              spacing={3}
                                              alignItems="center"
                                              justifyContent="space-between"
                                            >
                                              <Grid item>{`$${numberWithCommas(
                                                adp_paid
                                              )}`}</Grid>
                                              <Grid item>
                                                {adminUsers.includes(
                                                  cookies.get("username")
                                                ) ? (
                                                  <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                      setADPreturnDetails(
                                                        {
                                                          amount: adp_paid,
                                                          patient: `${first_name} ${last_name}`,
                                                          date_of_birth,
                                                          invoice_number,
                                                          healthcard_four,
                                                        },
                                                        handleOpen()
                                                      );
                                                    }}
                                                  >
                                                    Return
                                                  </Button>
                                                ) : null}
                                              </Grid>
                                            </Grid>
                                          ) : extraStatus &&
                                            extraStatus.length ? (
                                            <div>
                                              <div>
                                                {ADPpatient.extraStatus.map(
                                                  (status, index) => {
                                                    return status.dateADP >
                                                      delivery_time ? (
                                                      <div key={index}>
                                                        <Typography
                                                          component="div"
                                                          style={{
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {status.dateADP.toLocaleString(
                                                            "en-US",
                                                            {
                                                              year: "numeric",
                                                              month: "2-digit",
                                                              day: "numeric",
                                                            }
                                                          )}{" "}
                                                          {` - `}
                                                          <Box
                                                            component="span"
                                                            style={{
                                                              fontWeight:
                                                                "normal",
                                                            }}
                                                          >
                                                            {status.adpStatus}
                                                          </Box>
                                                        </Typography>
                                                      </div>
                                                    ) : (
                                                      <div key={index}>
                                                        Not yet submitted?
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            <div>Not yet submitted?</div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  );
                })}
              </TabPanel>
            );
          })}
          {ADPreturnDetails ? (
            <ReturnModal
              open={open}
              handleClose={handleClose}
              ADPreturnDetails={{ ADPreturnDetails }}
              username={username}
              password={password}
            />
          ) : null}
        </Box>
      ) : (
        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <CircularProgress
                style={{ display: "block", margin: "0 auto" }}
              />
              <br />
              <Typography style={{ textAlign: "center" }}>
                Loading data...
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ADPTracker;
