import { styled } from "@mui/material/styles";
import { Box, Grid, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Cookies from "universal-cookie";
import _, { set } from "lodash";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
const cookies = new Cookies();

// import bodyParser from 'body-parser';

function replacer(key, value) {
  if (key === "source") return undefined;
  else return value;
}

var groupByTime = require("group-by-time");

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

// let salesDataSortedByDate = [];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const SalesReport = () => {
  const [value, setValue] = React.useState(
    parseInt(cookies.get("activeClinic")) || 0
  );
  const [checked, setChecked] = React.useState(true);

  const [siteDataContext] = useSiteDataApi();

  const handleSwitchChange = (event, newValue) => {
    setChecked(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    cookies.set("activeClinic", newValue, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
  };

  const [salesData, setSalesData] = useState([]);
  const [yearlySalesData, setYearlySalesData] = useState([]);
  const [salesLast12Months, setSalesLast12Months] = useState([]);
  const [totalSales12Months, setTotalSales12Months] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await fetchReferralData();
    }
    fetchData();
  }, []);

  const fetchReferralData = async () => {
    try {
      // local version
      // const res = await fetch('http://localhost:5004/get-referral-data', {});
      // let data = {};
      // data.returnData = await res.json();

      const res = await fetch("/api/get-sales-data", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
      });

      const data = await res.json();

      if (data) {
        //sort the data a bit
        // let salesDataSortedByDate = [];
        // data.returnData.map((referral) => {
        //   salesDataSortedByDate['location'] = referral.location;
        //   return null;
        // });
        // console.log("salesData", data);
        const { sales, returns } = data.returnData;

        // console.log("salesData", sales);
        // console.log("returnData", returns);

        //sales contains an array of sales. returns contains an array of returns. we need to group the sales by month and year and by location (as well as a total for all locations), and subtract returns by month and by location, to get net sales for each month by location (and total).
        //each object in the sales array looks like this
        //{
        //     "invoice_number": 10030,
        //     "delivery_time": "2021-07-13 10:34:08-04",
        //     "client_id": 8,
        //     "trip_name": "London",
        //     "order_total": 200,
        //     "modified_time": "2021-07-13 10:39:46-04"
        // }
        //each object in the returns array looks like this:
        // {
        //   "return_order_id": 10013,
        //   "return_date": "2021-11-04",
        //   "client_id": 63,
        //   "invoice_number": 10049,
        //   "trip_name": "London",
        //   "return_total": 7499,
        //   "modified_time": "2021-11-04 16:39:03.716936"
        // }
        //use the order_total, trip_name (location) and delivery_time for sales, and return_date, trip_name (location), and return_total for returns. utilize lodash.
        //the final array will look like this:
        //[
        // {location:'London',netSales:[{'2021-01':10000},{'2021-02':20000},{'Total':30000}]},
        // {location:'Chatham',netSales:[{'2022-01':10000},{'2022-02':20000},{'Total':30000}]},
        // {location:'Total',netSales:[{'2023-01':20000},{'2023-02':40000},{'Total':60000}]}]
        //first, group the sales by location. when you do groupings, don't use MAPS or ENTRIES, keep everything in either arrays or objects
        // make sure to convert the delivery_time and return_date to a date object using moment

        //group the sales by year and then by location and then by month
        //group the returns by year and then by location and then by month
        //subtract the returns from the sales for each month and location
        // so it would look like this:
        // [{2021:{London:{'2021-01':10000,'2021-02':20000,'Total':30000},Chatham:{'2021-01':10000,'2021-02':20000,'Total':30000},Total:{'2021-01':20000,'2021-02':40000,'Total':60000}}},{2022:{London:{'2022-01':10000,'2022-02':20000,'Total':30000},Chatham:{'2022-01':10000,'2022-02':20000,'Total':30000},Total:{'2022-01':20000,'2022-02':40000,'Total':60000}}},{2023:{London:{'2023-01':10000,'2023-02':20000,'Total':30000},Chatham:{'2023-01':10000,'2023-02':20000,'Total':30000},Total:{'2023-01':20000,'2023-02':40000,'Total':60000}}}]

        let salesByYear = _.groupBy(sales, (sale) => {
          return moment(sale.delivery_time).format("YYYY");
        });
        let returnsByYear = _.groupBy(returns, (returnItem) => {
          return moment(returnItem.return_date).format("YYYY");
        });
        // console.log("salesByYear", salesByYear);
        // console.log("returnsByYear", returnsByYear);
        let salesByYearByLocation = {};
        let returnsByYearByLocation = {};
        Object.keys(salesByYear).map((year) => {
          salesByYearByLocation[year] = _.groupBy(
            salesByYear[year],
            "trip_name"
          );
          return null;
        });
        Object.keys(returnsByYear).map((year) => {
          returnsByYearByLocation[year] = _.groupBy(
            returnsByYear[year],
            "trip_name"
          );
          return null;
        });
        // console.log("salesByYearByLocation", salesByYearByLocation);
        // console.log("returnsByYearByLocation", returnsByYearByLocation);
        let netSalesByYearByLocation = {};
        Object.keys(salesByYearByLocation).map((year) => {
          netSalesByYearByLocation[year] = {};
          Object.keys(salesByYearByLocation[year]).map((location) => {
            netSalesByYearByLocation[year][location] = 0;
            salesByYearByLocation[year][location].map((sale) => {
              netSalesByYearByLocation[year][location] += sale.order_total;
              return null;
            });
            if (returnsByYearByLocation[year][location]) {
              returnsByYearByLocation[year][location].map((returnItem) => {
                netSalesByYearByLocation[year][location] -=
                  returnItem.return_total;
                return null;
              });
            }
            return null;
          });
          return null;
        });
        // console.log("netSalesByYearByLocation", netSalesByYearByLocation);
        let netSalesByYearByLocationByMonth = {};
        Object.keys(salesByYearByLocation).map((year) => {
          netSalesByYearByLocationByMonth[year] = {};
          Object.keys(salesByYearByLocation[year]).map((location) => {
            netSalesByYearByLocationByMonth[year][location] = {};
            salesByYearByLocation[year][location].map((sale) => {
              let month = moment(sale.delivery_time).format("YYYY-MM");
              if (netSalesByYearByLocationByMonth[year][location][month]) {
                netSalesByYearByLocationByMonth[year][location][month] +=
                  sale.order_total;
              } else {
                netSalesByYearByLocationByMonth[year][location][month] =
                  sale.order_total;
              }
              return null;
            });
            if (returnsByYearByLocation[year][location]) {
              returnsByYearByLocation[year][location].map((returnItem) => {
                let month = moment(returnItem.return_date).format("YYYY-MM");
                if (netSalesByYearByLocationByMonth[year][location][month]) {
                  netSalesByYearByLocationByMonth[year][location][month] -=
                    returnItem.return_total;
                } else {
                  netSalesByYearByLocationByMonth[year][location][month] =
                    returnItem.return_total;
                }
                return null;
              });
            }
            return null;
          });
          return null;
        });
        console.log(
          "netSalesByYearByLocationByMonth",
          netSalesByYearByLocationByMonth
        );
        setYearlySalesData(netSalesByYearByLocationByMonth);

        let salesByLocation = _.groupBy(sales, "trip_name");
        let returnsByLocation = _.groupBy(returns, "trip_name");
        // console.log("salesByLocation", salesByLocation);
        // console.log("returnsByLocation", returnsByLocation);
        let salesByLocationByMonth = {};
        let returnsByLocationByMonth = {};
        Object.keys(salesByLocation).map((location) => {
          salesByLocationByMonth[location] = _.groupBy(
            salesByLocation[location],
            (sale) => {
              return moment(sale.delivery_time).format("YYYY-MM");
            }
          );
          return null;
        });
        Object.keys(returnsByLocation).map((location) => {
          returnsByLocationByMonth[location] = _.groupBy(
            returnsByLocation[location],
            (returnItem) => {
              return moment(returnItem.return_date).format("YYYY-MM");
            }
          );
          return null;
        });
        let netSalesByLocationByMonth = {};
        Object.keys(salesByLocationByMonth).map((location) => {
          netSalesByLocationByMonth[location] = {};
          Object.keys(salesByLocationByMonth[location]).map((month) => {
            netSalesByLocationByMonth[location][month] = 0;
            salesByLocationByMonth[location][month].map((sale) => {
              netSalesByLocationByMonth[location][month] += sale.order_total;
              return null;
            });
            if (
              returnsByLocationByMonth[location] &&
              returnsByLocationByMonth[location][month]
            ) {
              returnsByLocationByMonth[location][month].map((returnItem) => {
                netSalesByLocationByMonth[location][month] -=
                  returnItem.return_total;
                return null;
              });
            }
            return null;
          });
          return null;
        });
        let netSalesByLocationByMonthByTotal = {};
        Object.keys(netSalesByLocationByMonth).map((location) => {
          netSalesByLocationByMonthByTotal[location] = [];
          Object.keys(netSalesByLocationByMonth[location]).map((month) => {
            netSalesByLocationByMonthByTotal[location].push({
              [month]: netSalesByLocationByMonth[location][month],
            });
            return null;
          });
          netSalesByLocationByMonthByTotal[location].push({
            Total: Object.values(netSalesByLocationByMonth[location]).reduce(
              (a, b) => a + b,
              0
            ),
          });
          return null;
        });

        netSalesByLocationByMonthByTotal["Total"] = [];
        Object.keys(netSalesByLocationByMonthByTotal).map((location) => {
          if (location !== "Total") {
            netSalesByLocationByMonthByTotal[location].map((month) => {
              if (month.Total) {
                if (
                  netSalesByLocationByMonthByTotal["Total"].find(
                    (total) => total.Total
                  )
                ) {
                  netSalesByLocationByMonthByTotal["Total"].find(
                    (total) => total.Total
                  ).Total += month.Total;
                } else {
                  netSalesByLocationByMonthByTotal["Total"].push({
                    Total: month.Total,
                  });
                }
              } else {
                if (
                  netSalesByLocationByMonthByTotal["Total"].find(
                    (total) => total[Object.keys(month)[0]]
                  )
                ) {
                  netSalesByLocationByMonthByTotal["Total"].find(
                    (total) => total[Object.keys(month)[0]]
                  )[Object.keys(month)[0]] += month[Object.keys(month)[0]];
                } else {
                  netSalesByLocationByMonthByTotal["Total"].push(month);
                }
              }
              return null;
            });
          }
          return null;
        });
        //netSalesByLocationByMonthByTotal['Total'] needs to be grouped by year
        let netSalesByLocationByMonthByTotalByYear = {};
        Object.keys(netSalesByLocationByMonthByTotal).map((location) => {
          netSalesByLocationByMonthByTotalByYear[location] = _.groupBy(
            netSalesByLocationByMonthByTotal[location],
            (month) => {
              if (month.Total) {
                return moment().format("YYYY");
              } else {
                return moment(Object.keys(month)[0]).format("YYYY");
              }
            }
          );
          return null;
        });
        console.log(
          "netSalesByLocationByMonthByTotalByYear",
          netSalesByLocationByMonthByTotalByYear
        );

        setSalesData(netSalesByLocationByMonthByTotalByYear);

        //set salesLast12Months to be the netSales for the last 12 months by location and also a total for all locations. don't group by year, just store 12 items in an array with this format '2021-07':1000. the earliest should come first, there should be a total for each location, and also a total row for all locations for each month.
        let salesLast12MonthsByLocation = {};
        Object.keys(salesByLocationByMonth).map((location) => {
          salesLast12MonthsByLocation[location] = {};
          Object.keys(salesByLocationByMonth[location]).map((month) => {
            salesLast12MonthsByLocation[location][month] = 0;
            salesByLocationByMonth[location][month].map((sale) => {
              salesLast12MonthsByLocation[location][month] += sale.order_total;
              return null;
            });
            if (
              returnsByLocationByMonth[location] &&
              returnsByLocationByMonth[location][month]
            ) {
              returnsByLocationByMonth[location][month].map((returnItem) => {
                salesLast12MonthsByLocation[location][month] -=
                  returnItem.return_total;
                return null;
              });
            }
            return null;
          });
          return null;
        });
        let salesLast12MonthsByLocationByTotal = {};
        Object.keys(salesLast12MonthsByLocation).map((location) => {
          salesLast12MonthsByLocationByTotal[location] = [];
          Object.keys(salesLast12MonthsByLocation[location]).map((month) => {
            salesLast12MonthsByLocationByTotal[location].push({
              [month]: salesLast12MonthsByLocation[location][month],
            });
            return null;
          });
          salesLast12MonthsByLocationByTotal[location].push({
            Total: Object.values(salesLast12MonthsByLocation[location]).reduce(
              (a, b) => a + b,
              0
            ),
          });
          return null;
        });
        //remove any month that isn't from the past 12 months from the above so we need to get the current month and subtract 12
        let currentMonth = moment().format("YYYY-MM");
        let currentMonthMinus12 = moment()
          .subtract(11, "months")
          .format("YYYY-MM");
        Object.keys(salesLast12MonthsByLocationByTotal).map((location) => {
          salesLast12MonthsByLocationByTotal[location] =
            salesLast12MonthsByLocationByTotal[location].filter((month) => {
              if (month.Total) {
                return true;
              } else {
                if (
                  moment(Object.keys(month)[0]).isBetween(
                    currentMonthMinus12,
                    currentMonth,
                    undefined,
                    "[]"
                  )
                ) {
                  return true;
                } else {
                  return false;
                }
              }
            });
          return null;
        });
        //if there is a 'Total' item, drop it
        Object.keys(salesLast12MonthsByLocationByTotal).map((location) => {
          salesLast12MonthsByLocationByTotal[location] =
            salesLast12MonthsByLocationByTotal[location].filter((month) => {
              if (month.Total) {
                return false;
              } else {
                return true;
              }
            });
          return null;
        });
        //if there is a month that is missing, add it with a value of 0
        Object.keys(salesLast12MonthsByLocationByTotal).map((location) => {
          Array.from(Array(12).keys()).map((monthNumber) => {
            let currentMonth = moment()
              .subtract(monthNumber, "months")
              .format("YYYY-MM");
            if (
              !salesLast12MonthsByLocationByTotal[location].find(
                (month) => Object.keys(month)[0] === currentMonth
              )
            ) {
              salesLast12MonthsByLocationByTotal[location].push({
                [currentMonth]: 0,
              });
            }
            return null;
          });
          return null;
        });

        //sort the data by month and year, oldest first
        Object.keys(salesLast12MonthsByLocationByTotal).map((location) => {
          salesLast12MonthsByLocationByTotal[location].sort((a, b) => {
            if (Object.keys(a)[0] < Object.keys(b)[0]) {
              return -1;
            }
            if (Object.keys(a)[0] > Object.keys(b)[0]) {
              return 1;
            }
            return 0;
          });
          return null;
        });
        //add an average net sales value. do not include $0 net sales in the average calculation. so some locations will not divide by 12 months
        Object.keys(salesLast12MonthsByLocationByTotal).map((location) => {
          let total = 0;
          let count = 0;
          salesLast12MonthsByLocationByTotal[location].map((month) => {
            if (Object.values(month)[0] !== 0) {
              total += Object.values(month)[0];
              count++;
            }
            return null;
          });
          salesLast12MonthsByLocationByTotal[location].push({
            Average: total / count,
          });
          return null;
        });

        //setTotalSales12Months to the sales less returns per month for the last 12 months only

        let valueTotalSales12Months = {};
        sales.map((sale) => {
          let month = moment(sale.delivery_time).format("YYYY-MM");
          if (
            moment(month).isBetween(
              currentMonthMinus12,
              currentMonth,
              undefined,
              "[]"
            )
          ) {
            if (valueTotalSales12Months[month]) {
              valueTotalSales12Months[month] += sale.order_total;
            } else {
              valueTotalSales12Months[month] = sale.order_total;
            }
          }
          return null;
        });
        returns.map((returnItem) => {
          let month = moment(returnItem.return_date).format("YYYY-MM");
          if (
            moment(month).isBetween(
              currentMonthMinus12,
              currentMonth,
              undefined,
              "[]"
            )
          ) {
            if (valueTotalSales12Months[month]) {
              valueTotalSales12Months[month] -= returnItem.return_total;
            } else {
              valueTotalSales12Months[month] = -returnItem.return_total;
            }
          }
          return null;
        });
        // console.log("valueTotalSales12Months", valueTotalSales12Months);
        //add ['Total'] which is the sum of all the months in valueTotalSales12Months, and 'Average' which is an average of the 12 months of valueTotalSales12Months
        valueTotalSales12Months["Total"] = Object.values(
          valueTotalSales12Months
        ).reduce((a, b) => a + b, 0);
        valueTotalSales12Months["Average"] =
          valueTotalSales12Months["Total"] /
          Object.values(valueTotalSales12Months).filter((month) => month !== 0)
            .length;
        console.log("valueTotalSales12Months", valueTotalSales12Months);
        setTotalSales12Months(valueTotalSales12Months);
        console.log(
          "salesLast12MonthsByLocationByTotal",
          salesLast12MonthsByLocationByTotal
        );
        setSalesLast12Months(salesLast12MonthsByLocationByTotal);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box m={3} className="salesPage">
      {salesData["London"] ? (
        <div>
          {/* <Grid container alignItems="center" flexDirection="column" m={4}>
            <Grid item>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Fiscal Year</Typography>
                <AntSwitch
                  checked={checked}
                  onChange={handleSwitchChange}
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography>Last 12 Months</Typography>
              </Stack>
            </Grid>
          </Grid> */}
          {/* {Object.keys(salesData[key]).map((month, j) => {
                  // console.log('month', salesData[key][month]);
                  // console.log('month', month);
                  // console.log('month', Object.entries(salesData[key][month]));
                  if (Object.entries(salesData[key][month]).length) {
                    return (
                      <div
                        key={j}
                        style={{ marginTop: "20px" }}
                        className={`${checked ? "monthShow" : "monthHide"}`}
                      > */}
          {/* create a table that looks like below, using the salesData
                        | Year | July | August | September | October | November | December | January | February | March | April | May | June | Total |
                        2022 | 5 | 2 | 4 | 5 | 5 | 6 | 8 | 9 | 4 | 10 | 23 | 5 | 200 |
                        2023 | 6 | 7 | 3 | 5 | 5 | 6 | 8 | 9 | 4 | 10 | 23 | 5 | 202 |

                        */}

          <TableContainer>
            <Table
              sx={{ minWidth: "100%" }}
              aria-label="referral table"
              className="referralTable"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Year</StyledTableCell>
                  <StyledTableCell>January</StyledTableCell>
                  <StyledTableCell>February</StyledTableCell>
                  <StyledTableCell>March</StyledTableCell>
                  <StyledTableCell>April</StyledTableCell>
                  <StyledTableCell>May</StyledTableCell>
                  <StyledTableCell>June</StyledTableCell>
                  <StyledTableCell>July</StyledTableCell>
                  <StyledTableCell>August</StyledTableCell>
                  <StyledTableCell>September</StyledTableCell>
                  <StyledTableCell>October</StyledTableCell>
                  <StyledTableCell>November</StyledTableCell>
                  <StyledTableCell>December</StyledTableCell>
                  <StyledTableCell>Total</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {Object.entries(salesData["Total"]).map((year, i) => {
                  // create a total for each year
                  let total = 0;

                  Object.entries(year[1]).map((month, j) => {
                    //if there is a 'Total' key inside year[1], then don't add it to the total
                    if (Object.keys(month[1])[0] !== "Total")
                      total += month[1][Object.keys(month[1])[0]];
                    return null;
                  });
                  console.log(total);

                  return (
                    <StyledTableRow>
                      <StyledTableCell>{year[0]}</StyledTableCell>
                      {/* let currentMonth = Number(Object.keys(month[1])[0].substring(5, 7)); */}
                      {/* //loop 13 times, if the monthNumber matches the loop number then return the netsales, also keep a running total, and the last cell should be the total for that year */}
                      {Array.from(Array(12).keys()).map((monthNumber) => {
                        let currentMonth = monthNumber + 1;
                        let netSales = 0;
                        Object.entries(year[1]).map((month, j) => {
                          if (
                            Number(Object.keys(month[1])[0].substring(5, 7)) ===
                            currentMonth
                          ) {
                            netSales = month[1][Object.keys(month[1])[0]];
                          }
                          return null;
                        });
                        return (
                          <StyledTableCell>
                            {netSales > 0
                              ? netSales.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : null}
                          </StyledTableCell>
                        );
                      })}
                      <StyledTableCell>
                        {total.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Box m={3}>
            <Typography variant="h5" textAlign="center" gutterBottom>
              Last 12 Months
            </Typography>
            <TableContainer>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Location</StyledTableCell>
                  {/* create 12 styledtablecells, the first one should be the month name from 12 months ago, the last one should be the current month. first get the month from 12 months ago.*/}
                  {Array.from(Array(12).keys()).map((monthNumber) => {
                    let currentMonth = monthNumber + 1;
                    let monthName = moment()
                      .subtract(12 - currentMonth, "months")
                      .format("MMMM-YY");
                    return <StyledTableCell>{monthName}</StyledTableCell>;
                  })}

                  <StyledTableCell>Total</StyledTableCell>
                  <StyledTableCell>Average</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {/* use salesLast12Months to put the data into the table. the month needs to match the column month. there should be 1 row per location, and a total row at the bottom. the data in salesLast12Months is in the correct order */}
                {Object.entries(salesLast12Months).map((clinic) => {
                  return (
                    <StyledTableRow>
                      <StyledTableCell>{clinic[0]}</StyledTableCell>
                      {Object.entries(clinic[1]).map((month, j) => {
                        let netSales = month[1][Object.keys(month[1])[0]];
                        //don't print the Average row
                        if (Object.keys(month[1])[0] === "Average") {
                          return null;
                        }
                        return (
                          <StyledTableCell>
                            {netSales !== 0
                              ? netSales.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : null}
                          </StyledTableCell>
                        );
                      })}

                      <StyledTableCell>
                        {Object.values(clinic[1])
                          .map((month) => {
                            if (Object.keys(month)[0] !== "Total") {
                              return Object.values(month).reduce(
                                (a, b) => a + b,
                                0
                              );
                            } else {
                              return 0;
                            }
                          })
                          .reduce((a, b) => a + b, 0)
                          .toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                      </StyledTableCell>

                      <StyledTableCell>
                        {/* print the average net sales value using the 'Average' field.  you don't need to calculate anything here */}
                        {Object.values(clinic[1])
                          .map((month) => {
                            if (Object.keys(month)[0] === "Average") {
                              return Object.values(month)[0];
                            } else {
                              return 0;
                            }
                          })
                          .reduce((a, b) => a + b, 0)
                          .toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
                <StyledTableRow className="totalRowSales">
                  <StyledTableCell>Total</StyledTableCell>
                  {/* print using totalSales12Months the first 12 months of sales, then the Total, then the 'Average' */}
                  {Object.entries(totalSales12Months).map((month, j) => {
                    if (month[0] !== "Total" && month[0] !== "Average") {
                      return (
                        <StyledTableCell>
                          {month[1].toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </StyledTableCell>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <StyledTableCell>
                    {totalSales12Months &&
                      totalSales12Months["Total"] &&
                      totalSales12Months["Total"].toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                  </StyledTableCell>
                  <StyledTableCell>
                    {totalSales12Months &&
                      totalSales12Months["Average"] &&
                      totalSales12Months["Average"].toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </TableContainer>
          </Box>
        </div>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress style={{ display: "block", margin: "0 auto" }} />
            <br />
            <Typography style={{ textAlign: "center" }}>
              Loading sales data...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SalesReport;
